import { useCallback, useMemo, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { CampaignManagerContext } from '../CampaignManagerContext';
import { CandidateDetailsTab } from '../CandidateContext/searchv3/types';
import { initialState as searchv3InitialState } from '../CandidateContext/searchv3/useSearchV3Candidate';
import { CandidateDetailsVisibilityContextProvider } from '../CandidateDetailsVisibilityContext/CandidateDetailsVisibilityContext';
import { LoadedCvsContextProvider } from '../LoadedCvsContext';
import { CampaignTab, SelectedCampaignContextProps, SelectedCampaignState } from './SelectedCampaignContext.types';
import { useCandidateMatches } from './SelectedCampaignContext.useCandidateMatches';
import { useCandidates } from './SelectedCampaignContext.useCandidates';
import { initialFacetsState, useFacets } from './SelectedCampaignContext.useFacets';
import { initialFiltersState, useFilters } from './SelectedCampaignContext.useFilters';
import { useSelectedCandidate } from './SelectedCampaignContext.useSelectedCandidate';
import { initialStatsState, useStats } from './SelectedCampaignContext.useStats';
import { useRatedCandidatesQuery } from 'hooks/search/useRatedCandidatesQuery';
import { usePageContext } from 'hooks/shared/usePageContext';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { useRatedCandidatesStats } from './SelectedCampaignContext.useRatedCandidatesStats';

const initialState: SelectedCampaignState = {
  campaign: undefined,
  filters: initialFiltersState,
  candidates: { data: [], isLoading: true, total: 0 },
  matches: { data: {}, isLoading: true },
  stats: { data: initialStatsState, isLoading: true },
  facets: { data: initialFacetsState, isLoading: true },
  selectedTab: CampaignTab.New,
  setSelectedTab: () => {
    throw Error('not implemented');
  },
  selectedCandidate: undefined,
  setSelectedCandidate: () => {
    throw Error('not implemented');
  },
  selectedCandidateConfig: {
    candidateDetails: { initialTab: CandidateDetailsTab.CV },
  },
  setSelectedCandidateConfig: () => {
    throw Error('not implemented');
  },
  selectedPage: 1,
  setSelectedPage: () => {
    throw Error('not implemented');
  },
};

export const SelectedCampaignContext = createContext(initialState);

export const SelectedCampaignContextProvider = ({ children }: SelectedCampaignContextProps) => {
  const [selectedCandidateConfig, setSelectedCandidateConfig] = useState(initialState.selectedCandidateConfig);
  const pageContext = usePageContext();
  const isRatedCandidatesPage = pageContext === PageContext.RatedCandidates;

  const [selectedTab, setSelectedTab] = useState(isRatedCandidatesPage ? CampaignTab.Shortlisted : CampaignTab.New);
  const [selectedPage, setSelectedPage] = useState(1);
  const selectedCampaign = useContextSelector(CampaignManagerContext, (state) => state.selectedCampaign);

  const filters = useFilters();
  const ratedCandidatesQuery = useRatedCandidatesQuery({
    filters: filters,
    page: selectedPage,
    tab: selectedTab,
  });

  const campaignCandidatesQuery = useCandidates({
    filters: filters,
    page: selectedPage,
    tab: selectedTab,
    campaignId: selectedCampaign?.id,
  });

  const {
    candidates,
    total,
    isLoading: isCandidatesLoading,
  } = isRatedCandidatesPage ? ratedCandidatesQuery : campaignCandidatesQuery;

  const { matches, isLoading: isMatchesLoading } = useCandidateMatches({ candidates, tab: selectedTab });
  const campaignStats = useStats(selectedCampaign?.id);
  const ratedCandidatesStats = useRatedCandidatesStats();
  const { facets, isLoading: isFacetsLoading } = useFacets(selectedCampaign?.id);

  const { stats, isLoading: isStatsLoading } = isRatedCandidatesPage ? ratedCandidatesStats : campaignStats;

  const { selectedCandidate, setSelectedCandidate } = useSelectedCandidate(candidates, isCandidatesLoading);
  const [selectedCandidateOutreachModal, setSelectedCandidateOutreachModal] = useState<HTMLDivElement | null>(null);

  const handleSelectedTab = useCallback<React.Dispatch<React.SetStateAction<CampaignTab>>>((cb) => {
    setSelectedTab((oldTab) => {
      const newTab = typeof cb === 'function' ? cb(oldTab) : cb;

      if (oldTab !== newTab) {
        setSelectedCandidate(undefined);
        setSelectedCandidateConfig(searchv3InitialState.config);
        setSelectedPage(1);
      }

      return newTab;
    });
  }, []);

  const state = useMemo<SelectedCampaignState>(
    () => ({
      campaign: selectedCampaign,
      filters,
      candidates: { data: candidates, isLoading: isCandidatesLoading, total },
      matches: { data: matches, isLoading: isMatchesLoading },
      stats: { data: stats, isLoading: isStatsLoading },
      facets: { data: facets, isLoading: isFacetsLoading },
      selectedTab,
      setSelectedTab: handleSelectedTab,
      setSelectedCandidate,
      selectedCandidate,
      selectedPage,
      setSelectedPage,

      selectedCandidateConfig,
      setSelectedCandidateConfig,
      selectedCandidateOutreachModal,
      setSelectedCandidateOutreachModal,
    }),
    [
      selectedCampaign,
      filters,
      candidates,
      isCandidatesLoading,
      matches,
      isMatchesLoading,
      stats,
      isStatsLoading,
      facets,
      isFacetsLoading,
      selectedTab,
      selectedPage,
      total,
      selectedCandidate,
      selectedCandidateConfig,
      selectedCandidateOutreachModal,
    ],
  );

  return (
    <SelectedCampaignContext.Provider value={state}>
      <LoadedCvsContextProvider>
        <CandidateDetailsVisibilityContextProvider>{children}</CandidateDetailsVisibilityContextProvider>
      </LoadedCvsContextProvider>
    </SelectedCampaignContext.Provider>
  );
};
